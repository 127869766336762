import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';

function AccordionText(props) {
    return (
        <Accordion.Item eventKey={props.eventkey}>
            <Accordion.Header>
                <div class="text"><strong>{props.title}</strong> - {props.detail}</div>
            </Accordion.Header>
            <Accordion.Body>
                {props.description}
            </Accordion.Body>
        </Accordion.Item>
    );
}

class Resume extends Component {
    render() {
        return (<Col>
            <h4>Education</h4>
            <Accordion alwaysOpen>
                <AccordionText eventkey="0" title="University of Texas Rio Grande Valley" detail="Master of Science in Computer Science"
                    description={<ul>
                        <li><strong>Relevant Coursework:</strong><br></br>
                            Design and Analysis of Algorithms (CSCI 6323)<br></br>
                            Advanced Database Design and Implementation (CSCI 6333)<br></br>
                            Data Mining and Warehouse (CSCI 6366)</li>
                    </ul>}
                />
                <AccordionText eventkey="1" title="University of Texas Rio Grande Valley" detail="Bachelor of Science in Computer Science" description={<ul>
                    <li><strong>Cumulative GPA:</strong> 3.23</li>
                    <li><strong>Relevant Coursework:</strong><br></br>
                        Algorithms & Data Structures (CSCI 3333)<br></br>
                        Software Engineering (CSCI 3340)<br></br>
                        Internet Programming (CSCI 3342)<br></br>
                        Automata, Formal Languages & Theory of Computation (CSCI 4325)<br></br>
                        Robotics Programming (CSCI 4341)</li>


                </ul>} />
            </Accordion>
            <div class="classWithPad"></div>

            <h4>Experience</h4>
            <Accordion alwaysOpen>
                <AccordionText eventkey="0" title="University of Texas Rio Grande Valley" detail="Marketing Coordinator (Office of Engaged Scholarship and Learning)" description={<ul>
                    <li>Assisted with the creation and distribution of images and video through various channels including
                        social and traditional media.</li>
                    <li>Updated and improved official webpages through front-end and back-end maintenance.</li>
                    <li>Filmed and edited videos in order to explain existing programs and introduce new initiatives.</li>
                    <li>Supervised the printing, laminating and exhibition of posters to promote events and workshops.</li>
                </ul>} />
                <AccordionText eventkey="1" title="Netflix" detail="Netflix Pathways Bootcamp" description={<ul>
                    <li>Familiarity with the Spring Boot platform for the creation of robust REST APIs with appropriate OpenAPI documentation.</li>
                    <li>Experience in the interplay between MySQL and Spring Data JPA for database usage.</li>
                    <li>Proficiency in implementing GraphQL for CRUD purposes.</li>
                    <li>Knowledge of JUnit and MockMVC for testing suite creation.</li>
                </ul>} />
                <AccordionText eventkey="2" title="Science Mill" detail="Summer 2022 Intern" description={<ul>
                    <li>Served as initial Computer Science intern in organization history to determine internship feasibility.</li>
                    <li>Developed a social media plan for the usage of TikTok for advertising purposes.</li>
                    <li>Utilized Google App Script to interface with various Google Sheets files in order to match data from different sources.</li>
                    <li>Created a roadmap for the successful development of an Internship Program.</li>
                </ul>} />
            </Accordion>
            <div class="classWithPad"></div>

            <h4>Organizations</h4>
            <Accordion alwaysOpen>
                <AccordionText eventkey="0" title="UTRGV Eta Omicron Nu Honor Society" detail="President" description={<ul>
                    <li>Developed programs and activities for the organization using a long-term growth mindset to ensure club longevity.</li>
                    <li>Hosted Trivia Night activities using an innovative Excel and Powerpoint approach.</li>
                    <li>Assisted in the creation of images and video with the purpose of marketing the organization’s events.</li>
                </ul>} />
                <AccordionText eventkey="1" title="Frontera Devs" detail="Marketing Lead" description={<ul>
                    <li>Created multimedia content through an extensive research, planning, filming and editing process.</li>
                    <li>Took charge in the creation and distribution of laminated posters for physical marketing purposes.</li>
                    <li>Analyzed club membership statistics in order to optimize event attendance and improve marketing goals.</li>
                </ul>} />
            </Accordion>
            <div class="classWithPad"></div>

            <h4>Competitions</h4>
            <Accordion alwaysOpen>
                <AccordionText eventkey="0" title="Frontera Hacks" detail="General Participant" description={<ul>
                    <li>Participated in a team-based competition to create "Woof It - Dog Tracking Application" in a 24 hour cycle.</li>
                    <li>Collaborated with front-end and business divisions to accomplish project goals.</li>
                    <li>Created a reliable back-end system with a REST API and database implemented in Java.</li>
                </ul>} />
                <AccordionText eventkey="1" title="UTRGV Big Idea Competition" detail="Finalist, Social & Climate Impact Award Winner" description={<ul>
                    <li>Pitched a business idea to a panel of judges in a 5 minute presentation followed by a 3 minute Q&A session.</li>
                    <li>Received mentorship from a past competition winner in order to refine presentation slides and improve presentation style.</li>
                    <li>Identified a local business opportunity and developed it into an award-winning pitch.</li>
                </ul>} />
                <AccordionText eventkey="2" title="UTRGV Hack Research Hackathon" detail="General Participant" description={<ul>
                    <li>Collaborated in a team-based format to contribute to ongoing Computer Science research.</li>
                    <li>Utilized LaTeX formatting to synthesize findings into a standardized research paper format.</li>
                    <li>Presented research to a panel of fellow participants and university faculty.</li>
                </ul>} />
                <AccordionText eventkey="3" title="CAHSI South Texas Hackathon" detail="General Participant" description={<ul>
                    <li>Participated in Cybersecurity exercises in a controlled competitive environment.</li>
                    <li>Completed Digital Forensics, Cryptography and De-anonymization activities.</li>
                    <li>Used a modified Kali Linux virtual machine to complete various challenges.</li>
                </ul>} />
            </Accordion>
            <div class="classWithPad"></div>

            <h4>Skills</h4>
            <Accordion alwaysOpen>
                <AccordionText eventkey="0" title="Programming Languages" detail="C++, Java, Python and AutoHotKey" description={<ul>
                    <li>Proficiency in Python and associated libraries such as Pandas and MatPlotLib.</li>
                    <li>Experience in Java programming with an emphasis on database maintenance and creation.</li>
                    <li>Usage of C++ with a focus on pointers and references.</li>
                    <li>Familiarity with AutoHotKey in creating Windows scripts for quality-of-life improvements.</li>
                </ul>} />
                <AccordionText eventkey="1" title="Web Development" detail="Javascript, React and Flutter" description={<ul>
                    <li>Experience with standard HTML/CSS/JS workflow.</li>
                    <li>Implementation of React in creation of smarter webpages.</li>
                    <li>Extensive practice in utilizing Flutter for creating engaging and modern websites and applications.</li>
                </ul>} />
                <AccordionText eventkey="2" title="Multimedia Software" detail="Adobe Creative Suite" description={<ul>
                    <li>Over a decade of experience using After Effects for short-form content and special effects integration.</li>
                    <li>Familiarity with After Effects Expressions engine to integrate code for smarter multimedia projects.</li>
                    <li>Extensive Photoshop experience to create and prepare graphics for physical and digital marketing.</li>
                    <li>Proficiency using Adobe Premiere Pro to create long-form content to engage audiences.</li>
                </ul>} />
                <AccordionText eventkey="3" title="Documents and Data" detail="Microsoft Office Suite, LaTeX Markup." description={<ul>
                    <li>Extensive experience in Powerpoint, Outlook, Word and Excel.</li>
                    <li>Proficiency in the usage of LaTeX markup for the preparation of documentation and scientific reports.</li>
                </ul>} />
                
                <AccordionText eventkey="4" title="Languages" detail="Fluent in English and Spanish" description={<ul>
                    <li>Expertise in English in professional, academic and social contexts.</li>
                    <li>Life-long experience as a native Spanish speaker.</li>
                </ul>} />
            </Accordion>
            <div class="classWithPad"></div>
        </Col>);
    }
}

export default Resume;