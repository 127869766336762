import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';

class Transcript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const response = await fetch(process.env.PUBLIC_URL + '/courses.csv');
      const csvData = await response.text();
      this.handleCSVData(csvData);
    } catch (error) {
      console.error('Error loading CSV file:', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleCSVData = (csvData) => {
    const courses = csvData.split('\n').map((row) => row.split(','));

    if (courses[0][0].toLowerCase() === 'semester') {
      courses.shift();
    }

    const groupedData = courses.reduce((acc, course) => {
      const [semester, courseName] = course;
      if (!acc[semester]) {
        acc[semester] = [];
      }
      acc[semester].push(courseName);

      console.log(`Processing course for semester ${semester}: ${courseName}`);

      return acc;
    }, {});

    this.setState({ data: groupedData });
  };

  renderData() {
    const { data } = this.state;
    return (
      <Accordion>
        {Object.entries(data).map(([semester, courses], index) => (
          <Card key={index} className="mb-3"> {/* Add margin-bottom for spacing */}
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header as={Card.Header}>
                {semester}
              </Accordion.Header>
              <Accordion.Body as={Card.Body}>
                <ul>
                  {courses.map((course, idx) => (
                    <li key={idx}>{course}</li>
                  ))}
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    );
  }
  

  render() {
    const { loading } = this.state;

    return (
      <Container>
        {loading ? <p>Loading...</p> : this.renderData()}
      </Container>
    );
  }
}

export default Transcript;
