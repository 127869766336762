import React, { Component } from "react";
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';


class Publi extends Component {
    render() {
        return (
            <Col>
            <div class="classWithPad"></div>
                <h4>Science</h4>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <a href="https://utrgv.hackresearch.com/site/wp-content/uploads/2022/12/HackR22.pdf"><strong>Obtaining Useful Insights from Historical UTRGV Data</strong> - December 2022</a>
                        </Accordion.Header>
                        <Accordion.Body>
                            Miguel Ramirez, Hector Hinojosa <br></br>
                            3rd Hack Research Hackathon (HackR 2023)
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div class="classWithPad"></div>
                <h4>Culture</h4>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <a href="https://www.cdjapan.co.jp/feature/An_Introduction_to_the_Various_VTuber_Groups"><strong>An Introduction to the Various VTuber Groups (CDJapan)</strong> - February 2022</a>
                        </Accordion.Header>
                        <Accordion.Body>
                        Submitted as part of the CDJapan Ambassador Project.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        );
    }
}

export default Publi;