import React, { Component } from "react";
import {
  Route,
  Routes,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import Resume from "./Resume";
import Publi from "./Publi";
import Transcript from "./Transcript";
import { useState } from 'react';

class Main extends Component {
    render() {
      return (
        <HashRouter>
          <div>
            <ul className="header">
              <li><NavLink end to="/">Home</NavLink></li>
              <li><NavLink to="/resume">Resume</NavLink></li>
              <li><NavLink to="/transcript">Transcript</NavLink></li>
              <li><NavLink to="/publi">Publications</NavLink></li>
            </ul>
            <div className="container">
            <Routes>
              <Route exact path="/" element={<Home/>}/>
              <Route path="/resume" element={<Resume/>}/>
              <Route path="/transcript" element={<Transcript/>}/>
              <Route path="/publi" element={<Publi/>}/>
              </Routes>
            </div>
          </div>
        </HashRouter>
      );
    }
  }
 
export default Main;