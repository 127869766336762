import React, { Component } from "react";
import pfp from './images/mikeIMG.png'

class Home extends Component {
  render() {
    return (
        <div className="column">
            <div className="d-flex flex-row justify-content-md-center">
            <img  className="img-fluid max-width: 50% " src={pfp} alt="Responsive image" ></img>
            </div>
            <div className="alert alert-dark" role="alert">
              <div>
              Hi! I'm Mike Ramirez, welcome to my site. For business inquiries please e-mail me at <a href="mailto:ramirezmultimediaofficial@gmail.com" className="alert-link">ramirezmultimediaofficial@gmail.com</a>.
              </div>
              <div> Follow me on Github: <a href="https://github.com/MikeRamirez99" className="alert-link">MikeRamirez99</a></div>
              <div> Add me on LinkedIn: <a href="https://www.linkedin.com/in/mikeramirez1999/" className="alert-link">Miguel Ramirez</a></div>
          </div>
        </div>
    );
  }
}

export default Home;